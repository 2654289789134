import {useState, useEffect} from "react"
import { lang } from './lang'
import { FaPhoneAlt, FaFacebookF, FaEnvelope, FaTwitter, FaYoutube, FaTiktok } from 'react-icons/fa';
import { BsLine } from "react-icons/bs";
import api from './api'

const Footer = (props) => {
    const [menus, setMenus] = useState([]);
    const init = props.init;
    const styles = props.styles;
    useEffect(()=>{
        const fetchInit = async () =>{
            try {
              const response = await api.get('/menu?type=footer-menu');
              setMenus(response.data.results);
              
            } catch (err) {
                if(err.response){
                    //Not in 202 response range
                    console.log(err.response.data);
                    console.log(err.response.headers);
                    console.log(err.response.status);
                } else {
                    console.log(`Error: ${err.message}`);
                }
            }
          }
        fetchInit();
    },[]);

  return (
    <>
    <div className={"container "+styles["copyright"]}>
        <div className="left">
            <p>{init.footer}</p>
            <a href={process.env.PUBLIC_URL + "/panel"} target="_blank" rel="noopener noreferrer">(Staff Login)</a>
        </div>
        <div className="right">
            <ul className={styles["social-link"]}>
                {(init.facebook!=='')?<li><a href={init.facebook} target="_blank" rel="noreferrer" aria-label="Facebook" className="rounded-btn"><FaFacebookF /></a></li>:''}
                {(init.line!=='')?<li><a href={init.line} target="_blank" rel="noreferrer" aria-label="Line" className="rounded-btn"><BsLine /></a></li>:''}
                {(init.twitter!=='')?<li><a href={init.twitter} target="_blank" rel="noreferrer" aria-label="Twitter" className="rounded-btn"><FaTwitter /></a></li>:''}
                {(init.tiktok!=='')?<li><a href={init.tiktok} target="_blank" rel="noreferrer" aria-label="Ticktok" className="rounded-btn"><FaTiktok /></a></li>:''}
                {(init.youtube!=='')?<li><a href={init.youtube} target="_blank" rel="noreferrer" aria-label="Youtube" className="rounded-btn"><FaYoutube /></a></li>:''}
                <li><a href={`mailto:`+init.email} aria-label="Email" className="rounded-btn"><FaEnvelope /></a></li>
                <li><a href={`tel:`+init.phone} aria-label="Telephone" className="rounded-btn"><FaPhoneAlt /></a></li>
            </ul>
        </div>
    </div>
    </>
  )
}

export default Footer